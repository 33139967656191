import React, { FunctionComponent } from 'react'
import InputMessage, { InputMessageKind } from './InputMessage'

export type TextInputProps = {
  type: 'text' | 'password' | 'email' | 'time' | 'number' | 'date'
  value: string | number
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  onBlur?: (event: React.FormEvent<HTMLInputElement>) => void
  disabled?: boolean
  placeholder?: string
  message?: string
  valid?: boolean
  name?: string
  id?: string
  bgColor?: string
  autocomplete?: string
  kind?: string
}

export enum TextInputKind {
  primary = 'form-input font-body text-gray-900 block w-full h-10 rounded-xl px-4 py-2 text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
  rounded = 'form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-4 sm:px-8 py-2 text-xxs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5',
  roundedModal = 'form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5'
}

const TextInput: FunctionComponent<TextInputProps> = ({
  disabled,
  message,
  name,
  id,
  valid,
  placeholder,
  value,
  bgColor,
  type = 'text',
  autocomplete,
  onChange,
  kind,
  onBlur,
}) => {
  const disabledClass = disabled ? 'opacity-50 hover:shadow-none' : ''
  const handleOnchange = (event: React.FormEvent<HTMLInputElement>) => {
    if (!onChange) {
      return
    }
    onChange(event)
  }
  return (
    <>
      <div className={`flex mt-0 rounded-full w-full ${disabledClass}`}>
        <input
          placeholder={placeholder}
          autoComplete={autocomplete}
          disabled={disabled}
          value={value}
          name={name}
          id={id}
          onChange={handleOnchange}
          onBlur={onBlur}
          type={type}
          className={` ${kind}${
            bgColor ? bgColor : 'bg-gray-100'
          }`}
        />
      </div>
      {message ? <InputMessage kind={valid === false ? InputMessageKind.error : InputMessageKind.info}>{message}</InputMessage> : null}
    </>
  )
}

export default TextInput
