import { useMutation, useQueryClient } from "react-query"
import { useGqlClient } from "../client"
import { blockPharmacyProjectGroup } from "../mutations/blockPharmacyProjectGroup"
import { finishPresentations } from "../mutations/finishPresentations"
import { createAppointment } from "../mutations/appointment"
import { deleteAppointment } from "../mutations/appointment"
import { updateAppointment } from "../mutations/appointment"
import { unblockPharmacyProjectGroup } from "../mutations/blockPharmacyProjectGroup"
import toast from "../../ui-components/NewToast"

type FinishPresentationProps = {
  dbId: number
  projectId: number
  starttime: string
  timespent: number
  adjustedNumberOfParticipants: number
  rounds: number
}

type CreateAppointmentProps = {
  pharmacyId: string
  dbId: string
  date: string
  amount: number
  projectIds:  string[]
}

type DeleteAppointmentProps = {
  appointmentId: number
  dbId: number
}

type UnblockAppointmentProps = {
  pharmacyId: string
  dbId: string
  projectId: string
}

type UpdateAppointmentProps = {
  appointmentId: number
  dbId: number
  date: string
}

type BlockPharmacyProjectProps = {
  pharmacyId: string
  dbId: number
  projectId: string
}

export const useBlockPharmacyProjectGroup = () => {
  const gqlClient = useGqlClient()
  const queryClient = useQueryClient()
  const blockPharmacy = async (variables: BlockPharmacyProjectProps) => await gqlClient.request(blockPharmacyProjectGroup, variables)
  return useMutation(blockPharmacy, {
    onSuccess: () =>  {
      queryClient.refetchQueries('pharmacyProjectGroups') 
      queryClient.refetchQueries('userPharmacyProjectGroups')
      toast.show({ headline: 'Schulung wurde blockiertt.', type: 'success' })
    }, 
     onError: () => {
      toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
    },
  })
}

  export const useAddFinishPresentations = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const addFinishPresentations = async (variables: FinishPresentationProps) => await gqlClient.request(finishPresentations, variables)
    return useMutation(addFinishPresentations, {
      onSuccess: () => {
        queryClient.refetchQueries('userFinishedPresentationGroups')
        queryClient.refetchQueries('userAppointments')
        toast.show({ headline: 'Schulung wurde abgeschlossen.', type: 'success' })
       },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
      },
      })
    }

  export const useAddAppointment = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const addAppointment = async (variables: CreateAppointmentProps) => await gqlClient.request(createAppointment, variables)
    return useMutation(addAppointment, {
      onSuccess: () => {
        queryClient.refetchQueries('userAppointments')
        queryClient.refetchQueries('userPharmacyProjectGroups')
        toast.show({ headline: 'Schulung wurde avisiert.', type: 'success' })
        },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
      },
      })
    }

  export const useDeleteAppointment = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const removeAppointment = async (variables: DeleteAppointmentProps) => await gqlClient.request(deleteAppointment, variables)
    return useMutation(removeAppointment, {
      onSuccess: () => {
        queryClient.refetchQueries('userAppointments')
        queryClient.refetchQueries('userPharmacyProjectGroups')
        toast.show({ headline: 'Termin wurde gelöscht.', type: 'success' })
        },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
       },
      })
  }

  export const useUpdateAppointment = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const changeAppointment = async (variables: UpdateAppointmentProps) => await gqlClient.request(updateAppointment, variables)
    return useMutation(changeAppointment, {
      onSuccess: () => {
        queryClient.refetchQueries('userAppointments')
        toast.show({ headline: 'Termin wurde aktualisiert.', type: 'success' })
        },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
       },
      })
  }

  export const useUnblockAppointment = () => {
    const gqlClient = useGqlClient()
    const queryClient = useQueryClient()
    const unblockPharmacy = async (variables: UnblockAppointmentProps) => await gqlClient.request(unblockPharmacyProjectGroup, variables)
    return useMutation(unblockPharmacy, {
      onSuccess: () => {
        queryClient.refetchQueries('userPharmacyProjectGroups')
        queryClient.refetchQueries('pharmacyProjectGroups') 
        toast.show({ headline: 'Schulung wurde freigeschaltet.', type: 'success' })
        },
      onError: () => {
        toast.show({ headline: 'Etwas ist schief gelaufen.', type: 'error' })
       },
      })
  }