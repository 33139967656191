import React, { DetailedHTMLProps, FunctionComponent } from 'react'

export enum ButtonKind {
  primary = 'bg-blue-600 text-white border-blue-600 border border-solid hover:bg-blue-800 hover:border-blue-800 rounded-xl',
  outlinedPrimary = 'bg-white text-blue-700 border-blue-600 border border-solid hover:bg-blue-200 rounded-md',
  primaryModal = 'bg-blue-600 text-white border-blue-600 border border-solid hover:bg-blue-800 hover:border-blue-800 h-10 sm:h-14 rounded-full',

  primaryIntensive = 'bg-blue-650 text-white border-blue-600 border border-solid hover:bg-blue-800 hover:border-blue-800 rounded-md',
  outlinedPrimaryIntensive = 'bg-white text-blue-700 border-blue-600 border border-solid hover:bg-blue-200 rounded-md',

  secondary = 'bg-gray-600 text-white border-gray-600 border border-solid hover:bg-gray-900 hover:border-gray-900 rounded-xl',
  outlinedSecondary = 'bg-white text-gray-700 border-gray-600 border border-solid hover:bg-gray-300 rounded-md',

  secondaryGreen = 'bg-green-600 text-white border-green-600 border border-solid hover:bg-green-900 hover:border-green-900 rounded-md',
  outlinedSecondaryGreen = 'bg-white text-green-700 border-green-600 border border-solid hover:bg-green-300-hi rounded-md',

  secondaryPeach = 'bg-map-peach uppercase text-white rounded-full sm:rounded-xl font-bold h-20px sm:h-10',
  outlinedSecondaryPeach = 'bg-white text-green-700 border-green-600 border border-solid hover:bg-green-300-hi rounded-md',

  danger = 'bg-red-600 text-white hover:bg-red-800 rounded-md',
  outlinedDanger = 'bg-white border-red-600 border border-solid text-red-600 hover:bg-red-600 hover:text-white rounded-md',

  rounded = 'h-10 sm:h-14 rounded-full bg-gradient-to-r from-map-blue via-map-gray to-map-peach text-white border outline-none',
  transparend = 'bg-opacity-0 text-white border-solid'
}

export interface ButtonProps extends DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  kind: ButtonKind
  disabled?: boolean
  icon?: string | undefined
  onClick?: () => void
  children?: React.ReactNode
  title?: any
  input?: boolean
  monoIcon?: boolean
  customStyle?: string
  buttonWithIcon?: boolean
}

const Button: FunctionComponent<ButtonProps> = ({
  kind = ButtonKind.primary,
  disabled,
  icon,
  monoIcon,
  onClick,
  children,
  title = null,
  type = 'button',
  customStyle,
  buttonWithIcon,
  input,
  ...buttonProps
}) => {
  const disabledClass = disabled ? 'cursor-not-allowed bg-map-sand' : ''
  const inputClass = input ? 'flex-end w-10 -ml-10 rounded-xl p-2' : 'px-2 sm:px-4 py-3 sm:py-1.5'
  const iconClass = (input || monoIcon) ? '20px' : '30px'
  const monoIconClass = monoIcon ? 'px-4 py-1.5' : ''
  const withIconClass = buttonWithIcon ? 'w-150px h-12' : ''
  const withIconTextClass = buttonWithIcon ? ' text-xxxs sm:text-base pl-2': 'text-xxxs sm:text-sm'

  const handleOnclick = () => {
    if (!onClick) {
      return
    }
    onClick()
  }
  return (
    <span className={`inline-flex rounded-md ${customStyle}`}>
      <button
        disabled={disabled}
        onClick={handleOnclick}
        className={`font-body inline-flex items-center h-10 text-sm xl:text-base leading-6 font-medium transition ease-in-out duration-150 hover:shadow-sm ${inputClass} ${kind} ${disabledClass} ${customStyle} ${monoIconClass} ${withIconClass}`}
        type={type}
        {...buttonProps}
      >
        {icon && (
          <img
            src={icon}
            alt="Button Icon"
            style={{ height: iconClass, width: iconClass, maxWidth: 'none' }}
          />
        )}
        <span className={`${customStyle} ${withIconTextClass}`} title={title}>
          {children}
        </span>
      </button>
    </span>
  )
}

export default Button
