import Pagination from 'rc-pagination'
import React from 'react'
import Button, { ButtonKind } from './Button'

interface ICustomPagination {
  onChange?: React.Dispatch<React.SetStateAction<number>> | undefined
  current: number
  total: number
  handleSubmit: () => void
}

const CustomPagination: React.FC<ICustomPagination> = ({ onChange, current, total, handleSubmit }) => {
  const buttonText = 'WEITER'
  return (
    <Pagination
    className="sticky self-end bottom-0 w-full justify-between p-0 sm:p-4 pt-4 pb-6 place-items-stretch"
    onChange={onChange}
    current={current}
    total={total}
    disabled={false}
    pageSize={1}
    itemRender={(curr, type, element) => {
      switch (type) {
        case 'next':
          return (
            <Button 
              kind={ButtonKind.primaryModal} 
              customStyle={'w-full text-xl'}
              onClick={handleSubmit}
              >
              {buttonText}
            </Button>
          )
      }
    }}
    showTitle={false}
  />
  )
}

export default CustomPagination
