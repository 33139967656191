import { GraphQLClient } from 'graphql-request'
import { RequestDocument, Variables } from 'graphql-request'
//import { RequestDocument, Variables } from 'graphql-request/dist/types'
import { useAuth } from './hooks/useAuth'
import { Response, ResponseProgress } from './response'
import { ResponseErrors } from './response_errors'
import { UnauthenticatedError } from './unauthenticated_error'

export const DEFAULT_ENDPOINT_URL = process.env.REACT_APP_API_URL || ''

export const gqlClient = new GraphQLClient(DEFAULT_ENDPOINT_URL, { fetch: fetch })
export const useGqlClient = () => {
  const { token, userId } = useAuth()
  gqlClient.setHeaders(new Headers({ token: token, userId: userId }))
  return gqlClient
}

/**
 * ApoClient wraps the GraphQlClient provided by graphql-request.
 * It encapsulates state like token and custom header handling.
 */
export class ApoClient {
  private readonly endpoint: string
  private client: GraphQLClient

  /**
   *
   * @param endpoint The URL of the GraphQL API Endpoint
   */
  constructor(endpoint: string = DEFAULT_ENDPOINT_URL) {
    this.endpoint = endpoint
    this.client = new GraphQLClient(this.endpoint, { fetch: fetch })
  }

  /**
   * Adds a single header
   *
   * @param name The header name like Authorization
   * @param value The value you want to provide like a token
   */
  addHeader(name: string, value: string): void {
    this.client.setHeader(name, value)
  }

  /**
   * Sets multiple header at once
   * @param headers A Header object like { authorization: '<token>' }
   */
  setHeaders(headers: Headers): void {
    this.client.setHeaders(headers)
  }

  /**
   * Sends a GraphQL Query to the provided endpoint
   *
   * @param document A GraphQL Query which is passed to the API
   * @param variables Optional variables corresponding to the Query
   */
  async request<ModelType>(document: any, variables?: any): Promise<any> {
    try {
      const rawResponse = await this.client.rawRequest<ModelType>(document, variables)
      return new Response<ModelType>((rawResponse as any) as any, ResponseProgress.done)
    } catch (error) {
      const errorWithResponse = error as { response: any };
      console.error(errorWithResponse.response);
      const normalizedError = this.extractError(errorWithResponse.response);
      if (normalizedError) {
        throw normalizedError;
      }
    }
  }

  /**
   * Sends a GraphQL Query to the provided potected endpoint
   *
   * @param document A GraphQL Query which is passed to the API
   * @param token The authentication token recived by the client
   * @param userId The authentication userId recived by the client
   * @param variables Optional variables corresponding to the Query
   */
  async requestWithAuthentication<ModelType>(document: RequestDocument, token: string, userId: string, variables?: Variables): Promise<Response<ModelType>> {
    this.setHeaders(new Headers({ token: token, userId: userId }))
    return this.request(document, variables)
  }

  private extractError(errorResponse: ResponseErrors) {
    if (errorResponse?.errors?.length <= 0) {
      return undefined
    }

    const error = errorResponse.errors[0]
    if (error.extensions.category === 'authentication') {
      return new UnauthenticatedError(error.message)
    }

    if (error.extensions.category === 'custom') {
      return error.extensions.reason
    }

    return new Error()
  }
}
