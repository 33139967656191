import { gql } from "graphql-request"

export const createAppointment = gql`
  mutation createAppointment(
    $pharmacyId: ID!,
    $dbId: ID!,
    $date: ISO8601DateTime!,
    $projectIds: [ID!]!,
    $amount: Int!,
  ) {
    createAppointment(
      pharmacyId: $pharmacyId,
      dbId: $dbId,
      date: $date,
      projectIds: $projectIds,
      amount: $amount,
    ) {
      id
    }
  }
`

export const deleteAppointment = gql`
  mutation DeleteAppointment(
    $appointmentId: ID!, 
    $dbId: ID!
  ) {
    deleteAppointment(
      appointmentId: $appointmentId, 
      dbId: $dbId
    ) {
      appointmentId
    }
  }
`

export const updateAppointment = gql`
  mutation UpdateAppointment(
    $appointmentId: ID!, 
    $dbId: ID!,
    $date: ISO8601DateTime!
  ) {
    updateAppointment(
      appointmentId: $appointmentId, 
      dbId: $dbId,
      date: $date
    ) {
      appointmentId
    }
  }
`