import React from 'react'

const DropdownArrowIcon: React.FC = () => (
  <span className="flex ml-0 sm:ml-1.5">
    <svg xmlns="http://www.w3.org/2000/svg" width="11.524" height="5.5" viewBox="0 0 11.524 5.5">
      <g id="Ebene_2" data-name="Ebene 2">
        <g id="Ebene_1-2" data-name="Ebene 1">
          <path
            id="Pfad_46"
            data-name="Pfad 46"
            d="M1,1,5.762,4.5,10.524,1"
            fill="none"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  </span>
)

export default DropdownArrowIcon
