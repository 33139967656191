import React, { DetailedHTMLProps, FunctionComponent, LabelHTMLAttributes } from 'react'

export interface InputLabelProps extends DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement> {
  disabled?: boolean
  children?: React.ReactNode
}

const InputLabel: FunctionComponent<InputLabelProps> = ({ disabled, children, className, ...labelProps }) => {
  const disabledClass = disabled ? 'opacity-50' : ''

  const classNameToUse = `block text-xxs sm:text-base4 font-medium leading-5 text-gray-450 tracking-wide ${disabledClass}}`

  return (
    <div className={`${className}`}>
      <label {...labelProps} className={classNameToUse}>
        {children}
      </label>
    </div>
  )
}

export default InputLabel
