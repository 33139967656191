import { useCallback, useMemo, useState } from 'react'
import { IAuthContext } from '../../contexts/authContext' 
import { AUTH_UNAUTHENTICATED_EVENT } from '../../events'
import { ApoClient } from '../client'
import { getUser } from '../queries/user'
import { logIn as signInMutation } from '../mutations/user'
//import { ApoClient, getUser, signIn as signInMutation } from '../graphql'
import { MeResponse, User, userActions } from '../../types/user'
import { SignInResponse } from '../../types/signin'
import { useLocalStorage } from './useLocalStorage'

const AUTH_TOKEN_LOCAL_STORAGE_KEY = 'auth-token'
const USER_TOKEN_LOCAL_STORAGE_KEY = 'user-id'

/**
 * The concrete implementation of AuthContext as a custom Hook.
 */

export const useAuthProvider = (): IAuthContext => {
  const client = useMemo(() => new ApoClient(), [])
  const [user, setUser] = useState<User>()
  const [userId, setUserId] = useLocalStorage(USER_TOKEN_LOCAL_STORAGE_KEY,'')
  const [token, setToken] = useLocalStorage(AUTH_TOKEN_LOCAL_STORAGE_KEY, '')
  const abilities = useMemo<Set<typeof userActions[number]>>(() => {
    const setOfAbilities = new Set<typeof userActions[number]>()
    if (user) {
      user.roles.map((role) => role.abilities.map((ability) => setOfAbilities.add(ability.name)))
      return setOfAbilities
    }
    return setOfAbilities
  }, [user])

  const getUserData = useCallback(async () => {
    try {
      if (token !== '') {
        client.addHeader('token', token)
        client.addHeader('userId', userId)
      }

      const response = await client.request<MeResponse>(getUser)
      const currentUser = response.data?.me
      setUser(currentUser)
    } catch (error: unknown) {
      window.dispatchEvent(new Event(AUTH_UNAUTHENTICATED_EVENT))
    }
  }, [client, token, userId])

  // implementation goes here
  const signIn = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await client.request<SignInResponse>(signInMutation, { email, password })
      const authToken = response.data?.login.user.token
      const userId = response.data?.login.user.id
      if (authToken && userId) {
        setToken(authToken)
        setUserId(userId)
        client.addHeader('token', authToken)
        client.addHeader('userId', userId)
        return true
      } else {
        console.error('AuthToken is undefined')
      }
    } catch (error) {
      console.error('Sign in Request went wrong', error)
    }
  
    return false
  }
  

  const signOut = () => {
    setToken('')
  //  setUser(null)
    client.addHeader('token', '')
    client.addHeader('userId', '')
  }

  return {
    abilities,
    user,
    userId,
    token,
    signIn,
    signOut,
    getUserData,
  }
}
