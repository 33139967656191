import React, { FunctionComponent } from 'react'

export enum TabBarItemKind {
  primary = `
    primary px-4 md:px-2 py-6 md:py-2 font-body font-medium
    text-lg md:text-base leading-4 xl:leading-5 rounded-md md:hover:border-solid
    focus:outline-none text-white md:hover:bg-blue-700
    md:focus:text-blue-200 md:focus:bg-blue-800 focus:outline-none overflow-visible uppercase md:normal-case`,

  secondary = `
      secondary px-2 py-2 font-body font-medium text-base
      leading-4 xl:leading-5 rounded-md hover:border-solid focus:outline-none
      text-gray-700 focus:outline-none hover:text-blue-700
      hover:bg-blue-100 active:text-blue-800 active:bg-blue-200 overflow-visible
      focus: text-underline`,
}

export type TabBarItemProps = {
  kind: TabBarItemKind
  selected: boolean
  disabled?: boolean
  children?: React.ReactNode
  onClick?: () => void
}

const composeCss = (selected: boolean, kind: TabBarItemKind) => {
  if (selected && kind === TabBarItemKind.primary) {
    return `
      primary-active px-4 md:px-2 py-6 md:py-2 font-body
      font-medium text-lg md:text-base leading-4 rounded-md xl:leading-5
      md:focus:text-blue-200 md:focus:bg-blue-400 md:focus:outline-none
      overflow-visible text-white bg-blue-600 focus:outline-none md:shadow-sm uppercase md:capitalize`
  }

  if (selected && kind === TabBarItemKind.secondary) {
    return `
      secondary-active px-2 py-2
      font-body font-medium text-base
      leading-4 xl:leading-5 rounded-md focus:text-blue-200
      focus:bg-blue-800 focus:outline-none overflow-visible
      text-white bg-blue-600 focus:outline-none shadow-sm capitalize`
  }

  return kind
}

const TabBarItem: FunctionComponent<TabBarItemProps> = ({ kind = TabBarItemKind.primary, onClick, selected, disabled, children }) =>
  disabled ? (
    <span className={`${composeCss(selected, kind)}`}>{children}</span>
  ) : (
    <button onClick={onClick} className={`cursor-pointer whitespace-no-wrap ${composeCss(selected, kind)}`}>
      {children}
    </button>
  )

export default TabBarItem
