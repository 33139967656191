import { AuthProvider } from './contexts/authContext'
import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import './App.css'
import ViewportProvider from './contexts/viewPort'
import { QueryClient, QueryClientProvider } from 'react-query'
import Pages from './pages/Pages'
import { secondsToMilliseconds } from 'date-fns'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: secondsToMilliseconds(60), // The minimum time before data is refetched
      notifyOnChangeProps: 'tracked',
    },
  },
})

const App: React.FC = () => (
  <AuthProvider>
    <QueryClientProvider client={queryClient}>
      <ViewportProvider>
        <Router>
          <Pages />
        </Router>
      </ViewportProvider>
    </QueryClientProvider>
  </AuthProvider>
)

export default App
