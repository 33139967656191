import React, { FunctionComponent, useContext, createContext, useState, ReactNode } from 'react'
import SignOutButton from './SignOutButton' 

export type NavBarProps = {
  logoUrl: string
  logoUrlSmall: string
  burgerMenu: string
  logoAlt: string
  burgerAlt: string
  burgerMenuClose: string
  children?: React.ReactNode
  className?: string
  isMenuOpen: boolean
  handleToggleMenu: () => void
}

interface INavContext {
  navExpanded: boolean
  toggleNav: () => void
}

const NavContext = createContext<INavContext | null>(null)

export function NavWrapper({ children }: { children: ReactNode }) {
  const [navExpanded, setNavExpanded] = useState<boolean>(false)
  const toggleNav = () => {
    setNavExpanded((prev) => !prev)
  }
  return <NavContext.Provider value={{ navExpanded, toggleNav }}>{children}</NavContext.Provider>
}

export const useNavProvider = (): INavContext => {
  const navContext = useContext(NavContext)
  if (!navContext) {
    throw new Error('Navwrapper is needed to use the hook')
  }
  return navContext
}

const NavBar: FunctionComponent<NavBarProps> = ({ logoUrl, logoUrlSmall, logoAlt, burgerMenu, burgerAlt, burgerMenuClose, className = '', children, handleToggleMenu, isMenuOpen }) => {

  const isOpen = isMenuOpen ? 'flex flex-col' : 'hidden'
  return (
    <div className={`w-full shadow-md bg-blue-600 sticky top-0 ${className}`}>
    <div className="relative z-10 shadow">
      <div className={`transition ease-in-out duration-150 'h-24 xl:h-16 mx-auto flex justify-between sm:items-center px-4 sm:px-2 py-4 xl:px-6 sm:py-2 xl:px-6 md:justify-start`}>
        <div 
          className='cursor-pointer sm:hidden'
          onClick={handleToggleMenu}
        >
          {isMenuOpen ? (
              <img src={`${burgerMenuClose}`} alt={`${burgerAlt}`} className="h-8 w-auto" />
            ) : (
              <img src={`${burgerMenu}`} alt={`${burgerAlt}`} className="h-8 w-auto" />
            )}
        </div>
        <div className="mr-4 xl:mr-8 text-white hidden xl:block">
          <img className="h-8 w-auto" src={`${logoUrl}`} alt={`${logoAlt}`} />
        </div>
        <div className="mr-2 text-white block xl:hidden">
          <img className="h-8 sm:h-8 w-auto" src={`${logoUrlSmall}`} alt={`${logoAlt}`} />
        </div>
        <div className="hidden md:flex-1 md:flex md:items-center xl:justify-between md:space-x-2 xl:space-x-12">{children}</div>
        <div className='sm:hidden'>
          <SignOutButton />
        </div>
      </div>
      <div className={`${isOpen} md:hidden`}>{children}</div>
    </div>
  </div>
  )
}

export default NavBar
