import { PharmacyProjectGroup } from '../types/Pharmacy'
import { ModalKind } from './Modal'
import Modal from './Modal'
import React from 'react'
import { useModal } from '../contexts/modalContext'
import Button, { ButtonKind } from "../ui-components/Button"
import { useUnblockAppointment } from '../graphql/hooks/blockPharmacyProjectGroup'


interface Props {
  pharmacy: PharmacyProjectGroup
}

const UnblockPharmacyModal: React.FC<Props> = ({ pharmacy }) => {
  const { closeModal } = useModal()
  const { mutate: unblockPharmacy } = useUnblockAppointment()

  const onFormSubmit = async () => {
    const variables = {
      pharmacyId: pharmacy.pharmacy.id, 
      dbId: pharmacy.pharmacy.dbId!,
      projectId: pharmacy.projectGroups[0].project.id
    }

    unblockPharmacy(variables)
    closeModal()
  }

  return (
    <Modal kind={ModalKind.sm} title={'Apotheke freischalten'} onClose={closeModal}>
      <form className="flex flex-wrap w-full" onSubmit={onFormSubmit}>
        <div className="flex flex-col flex-wrap w-full py-8">
          <div className='text-center font-semibold text-xl py-4'>Wollen Sie die Apotheke freischalten?</div>
          <div className={`sticky self-end bottom-0 w-full flex flex-row justify-end p-0 pt-4 pb-6 place-items-stretch`}>
            <Button 
              kind={ButtonKind.primary} 
              onClick={onFormSubmit} 
              customStyle={'text-xl px-4'}
            >
              {'JA'}
            </Button>
            <Button 
              kind={ButtonKind.secondary} 
              onClick={() => closeModal()} 
              customStyle={'text-xl px-4'}
            >
              {'ABBRECHEN'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default UnblockPharmacyModal