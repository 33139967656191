import React, { ReactNode } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom';
import MainLayout from '../layouts/MainLayout'
import Spinner from '../components/loader.gif'
import { LOGIN_ROUTE, ALL_TRAINIGS_PAGE_ROUTE, MY_TRAINIGS_PAGE_ROUTE, FINISHED_TRAINIGS_PAGE_ROUTE, REPORTS, BOOKED_TRAININGS_ROUTE } from '../routes'
import { NavWrapper } from '../ui-components/NavBar'
import { ModalProvider } from '../contexts/modalContext';

const LoginPage = React.lazy(() => import('./LoginPage').then(module => ({ default: module.LoginPage })));
const AllTrainings = React.lazy(() => import('./AllTrainigs').then(module => ({ default: module.AllTrainigs })));
const MyTrainings = React.lazy(() => import('./MyTrainigs').then(module => ({ default: module.MyTrainigs })));
const Bookedrainings = React.lazy(() => import('./BookedTrainings').then(module => ({ default: module.Bookedrainings })));
const FinishedTrainings = React.lazy(() => import('./FinishedTrainings').then(module => ({ default: module.FinishedTrainigs })));
const Reports = React.lazy(() => import('./Reports').then(module => ({ default: module.Reports })));

const Pages: React.FC = () => (
  <NavWrapper>
    <Routes>
    <Route element={<Navigate to="/login" />} path="/" />
    <Route
      path={LOGIN_ROUTE}
      element={
          <React.Suspense fallback={<SpinnerComponent />}>
            <LoginPage />
          </React.Suspense>
      }
    />
    <Route
      path={ALL_TRAINIGS_PAGE_ROUTE}
      element={
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <AllTrainings />
          </React.Suspense>
        </MainLayout>
      }
    />
    <Route
      path={MY_TRAINIGS_PAGE_ROUTE}
      element={
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <Providers>
              <MyTrainings />
            </Providers>
          </React.Suspense>
        </MainLayout>
      }
    />
        <Route
      path={BOOKED_TRAININGS_ROUTE}
      element={
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <Providers>
              <Bookedrainings />
            </Providers>
          </React.Suspense>
        </MainLayout>
      }
    />
    <Route
      path={FINISHED_TRAINIGS_PAGE_ROUTE}
      element={
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <FinishedTrainings />
          </React.Suspense>
        </MainLayout>
      }
    />
    <Route
      path={REPORTS}
      element={
        <MainLayout>
          <React.Suspense fallback={<SpinnerComponent />}>
            <Reports />
          </React.Suspense>
        </MainLayout>
      }
    />
  </Routes>
  </NavWrapper>
  )

  const Providers: React.FC<{ children: ReactNode }> = ({ children }) => (
    <ModalProvider>
      {children}
    </ModalProvider>
  )  

const SpinnerComponent = () => (
  <div className="h-screen w-screen bg-white z-20 absolute flex items-center justify-center">
    <div className="flex items-center justify-center flex-wrap max-w-48">
      <img className="flex w-24" src={Spinner} alt="" />
      <br />
      <div className="w-full text-center mx-auto flex justify-center font-body mt-6 text-base text-blue-400 leading-5">{'Daten werden geladen.'}</div>
    </div>
  </div>
)

export default Pages
