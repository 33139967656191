import React from 'react';
import Toast, { ToastProps } from './Toast';
import { createRoot } from 'react-dom/client';

export class ToastManager {
  private containerRef: HTMLDivElement;
  private toasts: ToastProps[] = [];

  constructor() {
    const body = document.getElementsByTagName('body')[0];
    const toastContainer = document.createElement('div');
    toastContainer.id = 'toast-container-main';
    toastContainer.classList.add('flex', 'fixed', 'bottom-8', 'right-0', 'flex-col', 'z-9999');
    body.insertAdjacentElement('beforeend', toastContainer);
    this.containerRef = toastContainer;
  }

  public show(options: Omit<ToastProps, 'destroy'>): void {
    const id = options.id ?? Math.random().toString(36).substring(2, 9);
    const toast: ToastProps = {
      id,
      ...options,
      destroy: () => this.destroy(id),
    };
    this.toasts = [toast, ...this.toasts];
    this.render();
  }

  public destroy(id: string): void {
    this.toasts = this.toasts.filter((toast) => toast.id !== id);
    this.render();
  }

  public destroyAll(): void {
    this.toasts = [];
    this.render();
  }

  private render(): void {
    const root = createRoot(this.containerRef);
    root.render(this.toasts.map((toastProps) => <Toast key={toastProps.id} {...toastProps} />));
  }
}

const toast = new ToastManager();

export default toast;
