import React, { FunctionComponent } from 'react'

export enum InputMessageKind {
  error = 'text-red-600',
  info = 'text-blue-600',
}

export type InputMessageProps = {
  kind: InputMessageKind
  children?: React.ReactNode
}

const InputMessage: FunctionComponent<InputMessageProps> = ({ kind, children }) => (
  <p className={`mt-1 font-body text-xs text-xxs pl-2 sm:pl-4 md:text-sm ${kind}`}>{children}</p>
)

export default InputMessage
