import { gql } from 'graphql-request'

export const logIn = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        token
      }
    }
  }
`
