import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react'
import AddFinishPresentationModal from '../components/AddFinishPresentationModal'
import AddAppointmentModal from '../components/AddAppointmentModal'
import UnblockPharmacyModal from '../components/UnblockPharmacyModal'
import DeletePharmacyModal from '../components/DeletePharmacyModal'
import EditAppointmentModal from '../components/EditAppointmentModal'
import { PharmacyProjectGroup } from '../types/Pharmacy'
import { Appointment } from '../types/Appointment'
/*
 * ENUM
 */

export enum ModalKind {
  CreateFinishPresentation,
  CreateAppointment,
  UnblockPharmacyModal,
  DeletePharmacyModal,
  EditAppointmentModal,
}
/*
 * TYPES
 */

interface IModalContext {
  openModal: (params: ModalProps) => void
  closeModal: () => void
}

type ModalProps =
  | {
      kind:
        | ModalKind.CreateFinishPresentation
        appointment: Appointment
    }
  | {
      kind:
        | ModalKind.CreateAppointment
      pharmacy: PharmacyProjectGroup
    }
  | {
      kind:
        | ModalKind.UnblockPharmacyModal
      pharmacy: PharmacyProjectGroup
    }
  | {
      kind:
        | ModalKind.DeletePharmacyModal
      appointment: Appointment
    }
  | {
      kind:
        | ModalKind.EditAppointmentModal
      appointment: Appointment
    }

/*
 * CONTEXT
 */
const ModalContext = createContext<IModalContext | null>(null)
ModalContext.displayName = 'ModalContext'
/*
 * PROVIDER
 */
export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [activeModal, setActiveModal] = useState<ModalProps | null>()

  const openModal = useCallback((params: ModalProps) => {
    setActiveModal(params)
  }, [])
  const closeModal = useCallback(() => {
    setActiveModal(null)
  }, [])

  return (
    <ModalContext.Provider value={{ closeModal, openModal }}>
      {activeModal ? <ModalRenderer {...activeModal} onClose={closeModal} /> : null}

      {children}
    </ModalContext.Provider>
  )
}
/*
 * RENDERER
 */
const ModalRenderer = (props: ModalProps & { onClose: () => void }) => {
  switch (props.kind) {
    case ModalKind.CreateFinishPresentation: {
      return <AddFinishPresentationModal appointment={props.appointment} />
    }
    case ModalKind.CreateAppointment: {
      return <AddAppointmentModal pharmacy={props.pharmacy} />
    }
    case ModalKind.UnblockPharmacyModal: {
      return <UnblockPharmacyModal pharmacy={props.pharmacy} />
    }
    case ModalKind.DeletePharmacyModal: {
      return <DeletePharmacyModal appointment={props.appointment} />
    }
    case ModalKind.EditAppointmentModal: {
      return <EditAppointmentModal appointment={props.appointment} />
    }
    default: {
      return null
    }
  }
}
/*
 * HOOK
 */
export const useModal = () => {
  const context = useContext(ModalContext)
  if (!context) {
    throw new Error('Trying to use ModalContext outside of the Provider')
  }
  return context
}