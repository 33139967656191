import NavBar, { useNavProvider } from '../ui-components/NavBar'
import TabBar from '../ui-components/TabBar'
import TabBarItem, { TabBarItemKind } from '../ui-components/TabBarItem'
import React, { ReactNode, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import SignOutButton from '../ui-components/SignOutButton'
import logoWhite from '../components/logoWhite.svg'
import burgerMenu from '../components/burgerMenu.png'
import closeIcon from '../components/CloseIcon.png'
import { ALL_TRAINIGS_PAGE_ROUTE, MY_TRAINIGS_PAGE_ROUTE, FINISHED_TRAINIGS_PAGE_ROUTE, REPORTS, BOOKED_TRAININGS_ROUTE } from '../routes'
import { useIsUserAdmin } from '../graphql/hooks/useUser'

interface MainLayoutProps {
    children: ReactNode;
  }

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { navExpanded } = useNavProvider()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { data: isUserAdmin } = useIsUserAdmin()

  const handleToggleMenu = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <div className="flex z-50 flex-wrap font-body">
      <>
        <div className={navExpanded ? 'hidden' : 'w-full'}>
          <NavBar 
            logoUrl={logoWhite} 
            logoUrlSmall={logoWhite} 
            logoAlt="CRM Logo" 
            burgerMenu={burgerMenu} 
            burgerAlt="Burger Menu"
            burgerMenuClose={closeIcon}
            handleToggleMenu={handleToggleMenu}
            isMenuOpen={isMenuOpen}
          >
            <TabBar>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === ALL_TRAINIGS_PAGE_ROUTE}
                onClick={() => {
                  navigate(ALL_TRAINIGS_PAGE_ROUTE)
                  handleToggleMenu()
                }}
              >
                Alle Schulungen
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === MY_TRAINIGS_PAGE_ROUTE}
                onClick={() => {
                  navigate(MY_TRAINIGS_PAGE_ROUTE)
                  handleToggleMenu()
                }}
              >
                Geblockte Apotheken
              </TabBarItem>
              <TabBarItem
                kind={TabBarItemKind.primary}
                selected={location.pathname === BOOKED_TRAININGS_ROUTE}
                onClick={() => {
                  navigate(BOOKED_TRAININGS_ROUTE)
                  handleToggleMenu()
                }}
              >
                Avisierte Schulungen
              </TabBarItem>
              <TabBarItem 
                kind={TabBarItemKind.primary} 
                selected={location.pathname === FINISHED_TRAINIGS_PAGE_ROUTE} 
                onClick={() => {
                  navigate(FINISHED_TRAINIGS_PAGE_ROUTE)
                  handleToggleMenu()
                }}
              >
                Durchgeführte Schulungen
              </TabBarItem>
              {isUserAdmin && (
                <TabBarItem 
                  kind={TabBarItemKind.primary} 
                  selected={location.pathname === REPORTS} 
                  onClick={() => {
                    navigate(REPORTS)
                    handleToggleMenu()
                  }}
                >
                  Reports
                </TabBarItem>
              )}
            </TabBar>

            <div className="flex items-center">
              <div className="flex space-x-2 xl:space-x-4">
              </div>
              <div className="hidden sm:flex items-center text-white">
                <SignOutButton />
              </div>
            </div>
          </NavBar>
        </div>
      </>
      {children}
      <div className="fixed -z-10 bottom-0 left-0 w-full bg-blue-600 sm:bg-white sm:white h-40px sm:h-98px flex justify-center sm:justify-between">
        <div className="hidden sm:text-map-black sm:block m-10">© 2024 aposphäre</div>
        <div className="m-2 sm:m-10">
          <a className="text-white sm:text-map-black font-light sm:font-normal hover:underline" href="https://www.aposphaere.de/impressum/" target="_blank" rel="noopener noreferrer">
            {'Impressum'}
          </a>
          <span className="text-white sm:text-map-black font-light sm:font-normal m-4">|</span>
          <a className="text-white sm:text-map-black font-light sm:font-normal hover:underline" href="https://www.aposphaere.de/datenschutz/" target="_blank" rel="noopener noreferrer">
            {'Datenschutz'}
           </a>
        </div>
      </div>
    </div>
  )
}

export default MainLayout;
