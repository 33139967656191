import InputLabel from '../ui-components/InputLabel'
import { TextInputKind } from '../ui-components/TextInput'
import { ModalKind } from './Modal'
import Modal from './Modal'
import { Field } from 'formik'
import React, { useState } from 'react'
import { useModal } from '../contexts/modalContext'
import { Appointment } from '../types/Appointment'
import { format, parse, isBefore, parseISO } from 'date-fns'
import { formatInTimeZone } from 'date-fns-tz'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Button, { ButtonKind } from '../ui-components/Button'
import { useUpdateAppointment } from '../graphql/hooks/blockPharmacyProjectGroup'
import toast from '../ui-components/NewToast'

interface Props {
  appointment: Appointment
}

const EditAppointmentModal: React.FC<Props> = ({ appointment }) => {

  const rawDate = new Date(appointment.date)

  const initialDate = format(rawDate, 'yyyy-MM-dd');

  const initialTime = formatInTimeZone(rawDate, 'UTC', 'HH:mm');

  const [pharmacyName] = useState<string>(appointment.pharmacy.name)
  const [date, setDate] = useState<string>(initialDate)
  const [time, setTime] = useState<string>(initialTime)
  const { closeModal } = useModal()
  const { mutate: changeAppointment } = useUpdateAppointment()
  const areNotValidated = (time.length < 4 || date.length < 8) ? true : false

  const onFormSubmit = async () => {
    const formatDate = (date: string, time: string) => {

      const dateTimeString = `${date}T${time}:00`
      const parsedDate = parseISO(dateTimeString)
      
      const formattedDate = format(parsedDate, "yyyy-MM-dd'T'HH:mm:ss'Z'")
      
      return formattedDate
    }

    formatDate(date, time)
    const formattedDateTime = formatDate(date, time)

    if (areNotValidated) {
      toast.show({
        headline: 'Bitte geben Sie alle Daten ein',
        type: 'error',
      })
      return 
    }

    const variables = {
      date: formattedDateTime,
      appointmentId: appointment.id,
      dbId: appointment.dbId,
    }

    changeAppointment(variables)
    closeModal()
  }

  const today = new Date();
  const ModalSchema = Yup.object().shape({
    time: Yup.string()
      .min(4, 'Zu kurz!')
      .required('Bitte auswählen'),
      date: Yup.string()
      .min(8, 'Zu kurz!')
      .required('Bitte auswählen')      
      .test('is-future-month', 'Das ausgewählte Datum darf nicht in einem vergangenen Monat liegen', function (value) {
        if (!value) return true; 
        const selectedDate = parse(value, 'yyyy-MM-dd', new Date()); 
        const isPast = isBefore(selectedDate, new Date(today.getFullYear(), today.getMonth(), 1)); 
        return !isPast;
      }),
  });

  return (
    <Modal kind={ModalKind.ms} title={'Termin bearbeiten'} onClose={closeModal}>
      <Formik
        initialValues={{ name: pharmacyName, date: date, time: time }}
        onSubmit={onFormSubmit}
        enableReinitialize
        validationSchema={ModalSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="flex flex-wrap w-full">
            <div className="flex flex-col flex-wrap w-full">
              <div className="w-full p-0 sm:p-2">
                <div className="w-full flex flex-col">
                  <div className="px-6 py-0 sm:py-2">
                    <InputLabel className={'flex flex-col items-center'}>{'Firmenname'}</InputLabel>
                    <div className="mt-1 rounded-md">
                      <Field
                        type="text"
                        name="name"
                        className="form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                        kind={TextInputKind.roundedModal}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className='flex flex-col sm:flex-row justify-between'>
                    <div className="w-full px-6 py-2">
                      <InputLabel className={'flex flex-col items-center'}>{'Datum'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <Field
                          type="date"
                          value={date}
                          className="form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                          kind={TextInputKind.roundedModal}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDate(e.target.value)}
                        />
                        {errors.date && touched.date ? (
                          <div className="text-red-600 text-xs mt-1">{errors.date}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="w-full px-6 py-2">
                      <InputLabel className={'flex flex-col items-center'}>{'Uhrzeit (24-Stunden-Format)'}</InputLabel>
                      <div className="mt-1 rounded-md">
                        <Field
                          type="time"
                          value={time}
                          className="form-input font-body text-gray-250 block w-full h-10 sm:h-14 rounded-full px-8 py-4 text-xs sm:text-base leading-6 border outline-none focus:shadow-focus focus:border-4 border-solid border-gray-400 focus:border-blue-400 sm:text-base sm:leading-5"
                          kind={TextInputKind.roundedModal}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTime(e.target.value)}
                        />
                        {errors.time && touched.time ? (
                          <div className="text-red-600 text-xs mt-1">{errors.time}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <Button
                  kind={ButtonKind.primaryModal}
                  type="submit"
                  disabled={isSubmitting} 
                  customStyle={'w-full text-xl p-6'}
                >
                  {'SCHULUNG BEARBEITEN'}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </Modal>
  )
}

export default EditAppointmentModal