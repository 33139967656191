import React, { FunctionComponent } from 'react'

export type TabBarProps = {
  justifyContent?: string
  children?: React.ReactNode
}

const TabBar: FunctionComponent<TabBarProps> = ({ justifyContent, children }) => (
  <div className="w-full">
    <nav className={`w-full justify-start items-start md:items-center flex flex-col md:flex-row md:space-x-1 ${justifyContent}`}>{children}</nav>
  </div>
)

export default TabBar
