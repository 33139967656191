import { useQuery } from "react-query"
import { useGqlClient } from "../client"
import { QueryOptions } from "../utils"
import { useAuth } from "./useAuth"
import { userAdmin } from "../queries/user"

type Props = {
  isUserAdmin: boolean
} 

export const useIsUserAdmin = (options?: QueryOptions<Props>) => {
    const gqlClient = useGqlClient()
    const { userId } = useAuth()
    return useQuery(
      'userAdmin', 
      async () => {
        const data = await gqlClient.request<{ isUserAdmin: Props }>(userAdmin)
        return data?.isUserAdmin 
      },
      { enabled: !!userId, staleTime: Infinity, ...options },
    )
  }