import React, { useEffect } from 'react'
import Button, { ButtonKind} from './Button'
import IconButton, { IconButtonKind} from './IconButton'
import CloseIcon from './CloseIcon'
import DangerIcon from './DanferIcon'
import DoneIcon from './DoneIcon'
import NoteAttentionIcon from './NoteAttentionIcon'

export enum ToastType {
  error = 'bg-red-600',
  info = 'bg-gray-700',
  success = 'bg-green-600',
}

export interface ToastProps {
  id?: string
  type?: keyof typeof ToastType
  destroy: () => void
  duration?: number
  headline?: React.ReactNode
  subline?: React.ReactNode
  button?: {
    onClick: () => void
    text: string
    kind?: ButtonKind
  }
  className?: string
}

const Toast = (props: ToastProps) => {
  const { destroy, headline, subline, type = 'info', button, duration = 4000 } = props
  const kind = ToastType[type]

  useEffect(() => {
    if (!duration) {
      return
    }

    const timer = setTimeout(() => {
      destroy()
    }, duration)

    return () => clearTimeout(timer)
  }, [destroy, duration])

  return (
    <div className="animate-slide-in pb-2 sm:pb-5 font-body">
      <div className="max-w-screen-lg mx-auto px-2 sm:px-6 lg:px-8">
        <div className={`p-2 rounded-lg ${kind} shadow-xl sm:p-3`}>
          <div className="flex items-center justify-between flex-wrap">
            <div className="w-full max-w-lg flex-1 flex items-center">
              <span className="flex p-2 rounded-lg text-white">
                {kind === ToastType.error ? <DangerIcon /> : ''}
                {kind === ToastType.info ? <NoteAttentionIcon /> : ''}
                {kind === ToastType.success ? <DoneIcon /> : ''}
              </span>
              <p className="ml-3 text-white">
                <span className="text-base font-medium leading-6">{headline}</span>
                <br />
                <span className="text-base font-normal mt-1">{subline}</span>
              </p>
            </div>
            {button ? (
              <div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2 text-white">
                <Button kind={button?.kind || ButtonKind.secondary} onClick={button.onClick}>
                  {button.text}
                </Button>
              </div>
            ) : null}
            <div className="order-3 flex-shrink-0 sm:order-3 sm:ml-2 text-white">
              <IconButton kind={IconButtonKind.custom} additionalCss="text-white" icon={<CloseIcon />} onClick={destroy} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const shouldRerender = (prevProps: ToastProps, nextProps: ToastProps) => prevProps.id === nextProps.id

export default React.memo(Toast, shouldRerender)
