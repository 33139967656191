import { ModalKind } from './Modal'
import Modal from './Modal'
import React from 'react'
import { useModal } from '../contexts/modalContext'
import Button, { ButtonKind } from "../ui-components/Button"
import { Appointment } from '../types/Appointment'
import { useDeleteAppointment } from '../graphql/hooks/blockPharmacyProjectGroup'

interface Props {
  appointment: Appointment
}

const DeletePharmacyModal: React.FC<Props> = ({ appointment }) => {
  const { closeModal } = useModal()
  const { mutate: removeAppointment } = useDeleteAppointment()


  const onFormSubmit = async () => {
    const variables = {
      appointmentId: appointment.id, 
      dbId: appointment.dbId!
    }

    removeAppointment(variables)
    closeModal()
  }

  return (
    <Modal kind={ModalKind.sm} title={'Termin löschen'} onClose={closeModal}>
      <form className="flex flex-wrap w-full" onSubmit={onFormSubmit}>
        <div className="flex flex-col flex-wrap w-full py-8">
          <div className='text-center font-semibold text-xl py-4'>Wollen Sie Termin löschen?</div>
          <div className={`sticky self-end bottom-0 w-full flex flex-row justify-end p-0 pt-4 pb-6 place-items-stretch`}>
            <Button 
              kind={ButtonKind.primary} 
              onClick={onFormSubmit} 
              customStyle={'text-xl px-4'}
            >
              {'JA'}
            </Button>
            <Button 
              kind={ButtonKind.secondary} 
              onClick={() => closeModal()} 
              customStyle={'text-xl px-4'}
            >
              {'ABBRECHEN'}
            </Button>
          </div>
        </div>
      </form>
    </Modal>
  )
}

export default DeletePharmacyModal